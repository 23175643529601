.btn-customlink {
  font-weight: 400;
  color: white;
  background-color: #232f3e;
  border: none;
  cursor: pointer;
}
.btn-customlink:hover {
  color: white;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-customlink:focus,
.btn-customlink.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-customlink:disabled,
.btn-customlink.disabled {
  color: #6c757d;
  pointer-events: none;
}

.splitter-layout {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 126px) !important;
  overflow: hidden;
}

.splitter-layout .layout-pane {
  position: relative;
  flex: 0 0 auto;
  overflow: auto;
}

.splitter-layout .layout-pane.layout-pane-primary {
  flex: 1 1 auto;
}

.splitter-layout > .layout-splitter {
  flex: 0 0 auto;
  width: 4px;
  height: 100%;
  cursor: col-resize;
  background-color: #ccc;
}

.splitter-layout .layout-splitter:hover {
  background-color: #bbb;
}

.splitter-layout.layout-changing {
  cursor: col-resize;
}

.splitter-layout.layout-changing > .layout-splitter {
  background-color: #aaa;
}

.splitter-layout.splitter-layout-vertical {
  flex-direction: column;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
  cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
  width: 100%;
  height: 4px;
  cursor: row-resize;
}

.layout-pane {
  min-height: 40px;
}
/* 
.splitter-layout.splitter-layout-vertical > .layout-splitter:before{
  content: "";
  display: block;
  background: #ccc url('../images/LeftArrow/leftarrow-icon.png') no-repeat;
  width: 15px;
  background-size: contain;
  height: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  background-color: white;
}  */

/* input[type="file"] {
  display: none;
} */
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.cardText {
  font-size: 12px;
}

 /*css for tables*/
/*.table-wrapper {*/
/*  margin-bottom: 25px;*/
/*  border-radius: 6px;*/
/*  background: #fff;*/
/*  overflow: hidden;*/
/*}*/

/*table {*/
/*  width: 100%;*/
/*  overflow-y: auto;*/
/*  table-layout: fixed !important;*/
/*  display: grid !important;*/
/*  !* grid-template-columns:*/
/*    minmax(100px, 0.5fr)*/
/*    minmax(200px, 3.5fr)*/
/*    minmax(150px, 1fr)*/
/*    minmax(150px, 1fr)*/
/*    minmax(150px, 1fr)*/
/*    minmax(150px, 1fr)*/
/*    minmax(150px, 1fr)*/
/*    minmax(150px, 1fr)*/
/*    minmax(150px, 1fr)*/
/*    auto; *!*/
/*  grid-template-columns: repeat(7, auto);*/
/*   grid-template-rows: repeat(auto, auto);*/
/*  grid-auto-rows: minmax(10px, auto);*/
/*  width: 100% !important;*/
/*}*/

/*table th {*/
/*  position: relative;*/
/*  font-size: 14px;*/
/*}*/
/*table th {*/
/*  position: relative !important;*/
/*  font-size: 14px !important;*/
/*}*/

/*table th,*/
/*table td {*/
/*  text-align: left !important;*/
/*  padding: 6px 10px !important;*/
/*  min-width: 100px !important;*/
/*  font-size: 14px !important;*/
/*}*/

/*table th span,*/
/*table td span {*/
/*  white-space: nowrap !important;*/
/*  text-overflow: ellipsis !important;*/
/*  overflow: hidden !important;*/
/*  display: block !important;*/
/*}*/

/*table tr td {*/
/*  border-top: 1px solid #ccc;*/
/*}*/

/*table thead,*/
/*table tbody,*/
/*table tr {*/
/*  display: contents !important;*/
/*}*/

.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 1;
  /* border-right: 1px solid #dee2e6; */
}
.resize-handle:hover {
  border-color: #ccc;
}
.resize-handle.active {
  border-color: #ccc;
  border-right: 1px dashed grey;
  cursor: col-resize;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* css for customised settings and top menus */
.customised-configuration {
  margin-left: 30px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
}
.react-switch {
  vertical-align: middle;
  margin-left: 14px;
}
.top-menu {
  display: flex;
  justify-content: flex-end;
  /* margin-right: 30px; */
  align-items: center;
  /* margin-top: 2px; */
}
.th-name {
  margin-left: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: black;
}
.attribute-column {
  font-size: 15px;
  margin-left: 20px;
  margin-top: 10px;
}
.attribute-text {
  font-size: 13px;
  color: grey;
}
.page-link {
  max-width: 30px;
  max-height: 33px;
  align-items: center;
  text-align: center;
  padding: 4px;
}
.search-bar {
  margin-right: 30px;
  display: flex;
  justify-content: flex-start;
  /* justify-content: start !important; */
  position: relative;
}
.setting-icon {
  cursor: pointer;
}

.icon-color{
  color:#545b64;
}
.sorting-icon {
  margin-left: 10px;
  cursor: pointer;
}
.status-info {
  margin-left: 5px;
  cursor: pointer;
}
.error-detail {
  margin-top: 20px !important;
}

/* CSS for non resizeable table */
/*table.custom_table_style {*/
/*  display: revert !important;*/
/*  grid-template-columns: none !important;*/
/*}*/

/*table.custom_table_style th span,*/
/*table.custom_table_style td span {*/
/*  white-space: nowrap !important;*/
/*  text-overflow: ellipsis !important;*/
/*  overflow: hidden !important;*/
/*  display: block !important;*/
/*}*/

/*table.custom_table_style tbody,*/
/*table.custom_table_style tr {*/
/*  display: revert !important;*/
/*}*/

/*table.resizeable-table thead th:first-child{*/
/*  text-align: center !important;*/
/*}*/

/* .text-overflow:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
} */

/* checkbox */
ul.checkboxes {
  list-style: none;
}

ul.checkboxes li input {
  margin-right: 1em;
}

ul.checkboxes li {
  float: left;
  min-width: 200px;
}

ul.checkboxes li:hover,
ul.checkboxes li.focus {
  width: 12em;
}

.checkbox-select-all {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #828282 !important;
  margin-left: 10px;
}

/* edit text styling */
.styles_edit__edit_button__hthOZ:before {
  color: #4200ff;
}

.styles_edit__view_container__2l2kB {
  display: flex !important;
}

.styles_edit__button__6H8n_ {
  outline: 0;
  padding: 0.6em;
  min-width: 30px;
  height: 100%;
  background-color: white;
  border-radius: 0.2rem;
  border: hidden !important;
}

.search {
  width: 40rem; /* 20rem */
  text-align: right;
  position: relative;
}
.search-icon {
  position: absolute;
  opacity: 0.5;
  left: 15px;
  top: 2px;
  /* z-index: 9999;  */
  /*this will keep the icon appearing all time, even when on input::focus*/
}
.search-box {
  font-size: 1rem;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.search-box:focus {
  outline: none;
  border: 1px solid #ced4da;
}
.search-btn {
  height: 100%;
  position: absolute;
  top: 30%;
  right: 0.5rem;
  opacity: 0.5;
  background-color: transparent;
  border: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAACnElEQVR4AcXZsUsbYRjH8e+dh2s1SyAGJwMJuDj1BIcEhJQIOnTq5F+QOf0jIq79A7oFh7aYyVBEkaZDC3awECc1AUXRIqUQotfFocnjJe/dk+b9PKP65Md7z13ee3Uwk2SNHKmngs5TnbDLJQqjA+RYZ4OXuDzvkSYf+cAJ44fPAYFhHeAzVhlqBBGrRoax8KjSJYhRXap4KCVoECiqQQKFLC0CZbXIElOBOwJ9cUchzm2Y5QsveN4tdfY4o00HSDHHPKuUmOV5v/D5SSSJ0MXfIY+HBB55dkIvRIIIvJDR28dnFJ/9kHH0MFaVDehRxlSZnuxAFUMZunKQKBJFUQ4wXTIYqcmPZ5GoFmUEahjw5eJTJI6ivBD4jCS/csrEVZZfU4yQk5OPhrwjcoRygQ0GVdCQf73OUEfisaMkHk1HDJHkYeDX82jlBzo+kCSEyxruwDP/EK1DbsWnhHDFgNTpodWjLgY9NECKfnvoyS4p8wBngN5Z/ABtQK8dP0AH0OuYB5iMqfAAMque7HJtHmAOPdnlxjzAPHqyy5V5gFX0ZJfj8AAn9CvhoeVRol8zPMAuj/xrlhW0Vpg1D3ApflhGR3b4wTlDvI24i4u+w9y0uyVrM213U1qxuy2/Z8bui8m23VezgGW7L6cBLdIWXs9FBAsHFCLCJI9opFMKXEzkkEp/IbK0bEdI0LARQRzVWoigPKy+Z5tlWooIiuP6NhVmAEiPNwLkqHDEw5CGx2wyDQDRI8T7l80U19xwxTFNmpwzKM1nFsyeCw7jFymCAxYjrHDp8r9cUOCUYRZ4Bw6AxVV47QJYXIVXLliNsOSC1Qh/XLAa4ZuDmmIcH1l2AaytwhZfmaAkn/qOb7eYBofJekOJJX7znfccAvwFyB3OeNys7d4AAAAASUVORK5CYII=");
}
.search-btn:hover {
  outline: none;
  opacity: 0.4;
  cursor: pointer;
}
.search-btn:focus {
  outline: none;
  opacity: 0.6;
}
/* tags input */
.react-tagsinput {
  background-color: unset;
  border: unset;
  overflow: hidden;
  padding-left: 5px;
}
.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  /* background-color: #6544D0;
  border-radius: 3px;
  border: 1px solid #6544D0; */

  background: #ffffff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 2.83871px;
  color: #777;

  /* color: #f8f8fb; */
  display: inline-block;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 6px;
  left: 0px;
  top: 0px;
  /* padding: 1px;
  padding-left: 4px;
  padding-right:4px */
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  color: #514e58;
  font-size: 0.85rem;
}

.react-tagsinput-tag a::before {
  content: "   ×";
}
.react-tagsinput-input {
  width: 80px;
  border: 1px solid #ccc;
  color: #777;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 400;
  margin-top: 6px;
  outline: none;
  border-radius: 3px;
  padding: 4px 6px;
  left: 0px;
  top: 0px;
}

input[type="checkbox"] {
  /* change "blue" color to xailient color */
  filter: hue-rotate(335deg);
}

input[type="radio"] {
  /* change "blue" color to xailient color */
  filter: hue-rotate(335deg);
}

/* Multi Step Form CSS */

ol.progtrckr {
  list-style-type: none;
  padding: 0;
  text-align: right;
}

ol.progtrckr li.progtrckr-doing {
  color: #ec7211;
  /* border-bottom: 4px solid #6544d0; */
  box-shadow: inset 0px -2px 0px #ec7211;
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li.progtrckr-todo {
  color: #828282;
  box-shadow: inset 0px -1px 0px #e0e0e0;

  /* border-bottom: 4px solid #828282; */
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}
.progtrckr em {
  display: none;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "";
  /* content:"\2022"; */
  color: #f3f0fc;
  background-color: #ec7211;
  width: 20px;
  line-height: 20px;
  border-radius: 50px;
}
ol.progtrckr li:before {
  position: relative;
  bottom: -65px;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  width: 20px;
  line-height: 20px;
}
ol.progtrckr li:before {
  position: relative;
  bottom: -65px;
  float: left;
  left: 50%;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

ol.progtrckr li.progtrckr-done {
  color: #828282;
  box-shadow: inset 0px -2px 0px #5cb85c;
  /* border-bottom: 2px solid #5cb85c; */
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
/*  */

.align-items {
  margin: auto;
  display: block !important;
}

.checkbox-select-all {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #828282 !important;
  margin-left: 10px;
}

label.customLabel > input.customRadio {
  /* HIDE RADIO Button and replace with image */
  display: none;
}
label.customLabel > input.customRadio + img {
  /* (UNCHECKED) Image Style */
  cursor: pointer;
  opacity: 0.1;
}
label.customLabel > input.customRadio:checked + img {
  /* (CHECKED) Image Style */
  opacity: 1;
}

ul.options {
  margin: 0 auto;
  padding: 0;
  max-height: 390px;
  overflow-y: auto;
  padding: 5px 5px 0 5px;
  border-left: none;
  border-right: none;
}

ul.options li {
  list-style: none;
  background-color: rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(
    90deg,
    #4200ff 10px,
    #eee 10px,
    #eee 11px,
    transparent 11px
  );
  padding: 10px 15px 10px 25px;
  border: 1px solid #ccc;
  box-shadow: inset 1px 1px 0 rgba(255, 255, 255, 0.5);
  margin-bottom: 5px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 3px;
}

ul.options li:hover {
  font-weight: bold;
  color: #333333;
  cursor: pointer;
  transition: 0.3s all;
}

.card .chooseDatasetTable .chooseClass {
  color: #212529;
  text-transform: CAPITALIZE;
  font-size: 14px;
  list-style: none;
}

.card .chooseDatasetTable .chooseClass label {
  color: #212529;
  text-transform: CAPITALIZE;
  font-size: 14px;
  list-style: none;
}

ul.checkboxes {
  list-style: none;
}

ul.checkboxes li input {
  margin-right: 1em;
}

ul.checkboxes li {
  float: left;
  min-width: 200px;
}

ul.checkboxes li:hover,
ul.checkboxes li.focus {
  width: 12em;
}

.center {
  margin: auto !important;
  display: block !important;
}

.vertical-divider {
  height: auto;
  border: 0;
  border-left: 1px solid #cccccc;
  display: inline-block;
  vertical-align: bottom;
  left: 0px;
  top: 0px;
  border: 0.482992px solid #e0e0e0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 24px;
}

.custom-listgroup {
  word-break: break-all;
  white-space: pre-wrap;
  padding: 0.25rem 0.25rem !important;
}

.card-custom {
  background: #ffffff;
  border: 0.965985px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 2.89795px;
  position: absolute;
}
.cardIcon {
  cursor: pointer;
}
/* css for statistics display */
.empty-data-span {
  text-align: "center";
  margin: auto;
  display: block;
}
.stats-header {
  text-align: center;
  margin: auto;
  display: block;
}
.data-loading {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin: 0 auto;
  display: block;
}
.statistics-spinner {
  color: black;
  margin: 0 auto;
  display: block;
}
.spinner-span {
  color: #828282;
  margin: 0 auto;
  display: block;
}
/* css for geometric properties */
.loading-spinner {
  color: black;
}
.plot {
  width: 100%;
  height: 100%;
}
.my-pretty-chart-container {
  text-transform: capitalize;
}
.instance-container {
  text-align: center;
}
h4 {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 20px !important;
  margin-top: 20px !important;
}

.pagination-wrapper {
  overflow-x:auto;
}

.pagination {
  display: table !important;
}
.pagination>li {
  display: table-cell !important;
}
.page-item.active .page-link{
  background-color: #6544d0;
  border-color: #6544d0;
}
.page-link {
  color: #6544d0;
}

.chevron-icon-size{
  font-size: 10px;
}

.btn-close::before{
  content: 'X' !important;
  visibility: visible;
  color: "black";
  font-weight: bold;
}

.sr-only::before{
  visibility: hidden;
}

.btn-close{
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  /* background-color: rgb(247, 12, 12, 0.5); */
}

body {
  background: #FFFFFF !important;
}

.alert-danger {
  color: #39393A;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #DD4442;
}
.alertmsg {
  display: inline-block;
  vertical-align: middle;
  width: 85%;
}
