.signOut {
  /* position: absolute !important;
  margin-top: 55vh !important; */
  position: fixed !important;
  margin-top: 60px;
  bottom: 0;
}
.profile {
  display: inline-block;
  vertical-align: middle;
  max-width: 75%;
}
.signOut{
  background: rgba(0, 0, 0, 0.25) !important;
  padding: 13px 25px 13px 13px;
  /* margin-bottom: 6px; */
  margin-bottom: 40px;
  border-radius: 6px;
  font-size: 11px;
}
.logout-btn{
  position: fixed !important;
  /* margin-top: 65px; */
  margin-left: 55px;
  bottom: 0;
  margin-bottom: 10px;
}
@media only screen and (max-height: 400px) {
  .signOut {
    position: relative !important;
    margin-top: 10px;
    bottom: 0;
    margin-bottom: 40px;
  }
  .logout-btn{
    position: relative !important;
    margin-top: 10px;
    bottom: 0;
  }
}

#company-name{
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
 }

.signOut-icon {
  display: inline;
  margin-right: 8px;
  /* padding-right: 18px; */
}
#sidebarbg {
  color: lightgrey !important;
}
.menu {
  /* padding-left: 10px; */
  position: static;
  height: 24px;
  left: 0px;
  right: 0px;
  top: 0px;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  /* White/Default */
  color: #ffffff;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 6px;
}
.CustomRow {
  margin-top: 30px !important;
}
.topbar {
  height: 80px !important;
}
.topbar .top-navbar .navbar-header {
  line-height: 100px !important;
}
.white-header{
 background-color: white!important;
}
.card-heading {
  position: static;
  /* width: 248px; */
  /* height: 24px; */
  left: 0px;
  top: 0px;
  /* Subheader - Heavy */
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #39393a;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 2px 0px;
}

.custom-card-text {
  position: static;
  /* width: 175px; */
  left: calc(50% - 175px / 2 - 138px);
  top: 48px;
  bottom: 0px;
  /* Body - Light */
  /* font-family: 'DM Sans';
    font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 2px 0px;
  color: rgba(0, 0, 0, 0.4) !important;
}
.page-wrapper {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #b9c0c8 !important;
}

.page-wrapper > .page-content {
  min-height: 100vh !important;
}

.topbar {
  box-shadow: unset;
  -webkit-box-shadow: unset;
  -o-box-shadow: unset;
  -moz-box-shadow: unset;
}

/* #main-wrapper[data-layout='vertical'][data-header-position='fixed'] .topbar {
    position: fixed !important;
    width: 15% !important;
} */

.list-group-item + .list-group-item {
  margin-top: -1px;
  border-top-width: 1px;
}

.number {
  position: static;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 2px solid #6e3ef5;
  box-sizing: border-box;
  border-radius: 50px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.numberDisabled {
  position: static;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: #b9c0c8;
  box-sizing: border-box;
  border-radius: 50px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.list-group-item.active {
  z-index: 2;
  color: #705af3;
  background-color: #fff;
  border-color: #fff;
}

.custom-topbar {
  color: white !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    #705af3;
}
/* .nav-link {
  padding: 0.5rem 0.5rem !important;
} */

.custom-card-title {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #39393a !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}
hr {
  margin-top: 0.1rem !important;
}
.custom-list {
  margin-left: -15px;
}
.timer {
  width: 24px;
  height: 24px;
}
.login-timer {
  width: 24px;
  height: 24px;
  margin-right: 10px !important;
}
.verification-link {
  margin-left: 37px !important;
}

body {
  background-image: linear-gradient(
      360deg,
      #271135 25.42%,
      rgba(39, 17, 53, 0) 65.22%
    ),
    url(../images/SVG/orchestraitbg.svg),
    linear-gradient(0deg, #271135, #271135) !important;
  /* background-size: 33.33% 100%;
  background-repeat: no-repeat no-repeat !important; */
  background-size: 100% !important;
  background-repeat: repeat-x !important;
  min-height: 100vh !important;
  width: 100% !important;
}

.alert-danger {
  color: #39393a;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #dd4442;
}
.alert-success {
  color: #39393a;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #5fa777;
}
.alertmsg {
  display: inline-block;
  vertical-align: middle;
  width: 85%;
}
.emailSuccessMsg {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #39393a;
}
.verifyEmailLoginMsg {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #39393a;
  margin-left: 37px !important;
}
/* reset password */
.redirection-link {
  margin-left: 4px;
  margin-right: 4px;
}
.reset-links {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
/* role mgmt */
.role-form {
  margin: 0 auto;
  width: 450px;
  background: white;
  padding: 40px;
  border-radius: 4px;
}
.select-role {
  width: 365px;
  height: 40px;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 5px;
}
.checkList {
  margin-left: 20px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  
}
.roles-checkbox {
  margin-left: 10px;
  margin-right: 5px;
}
.icon-button {
  cursor: pointer;
}
.column-width{
  width: 30%;
}
.list-container {
  display: flex;
  width: 230px;
}

.text-overflow{
  text-overflow: ellipsis;
    margin-bottom: -20px;
    cursor: pointer;
    word-break: break-all;
    overflow:hidden;
    white-space: nowrap;
}
.text-overflow:hover{
  overflow: visible; 
  white-space: normal;
  height:auto;  /* just added this line */
}

.btn-hover-color:hover{
  background-color:#5e72e4 ;
  box-shadow: none;
}
.primary-color{
  color:#5e72e4 ;
}
.primary-color:hover{
  color:#5e72e4 ;
}
.link-cursor-pointer{
  cursor: pointer !important;
}

.pagination-style{
  color: #7B68EE;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #7B68EE;
  border: 1px solid #705AF3;
}

.pagination-link-style{
  background: none;
  color: #705AF3;
}

.pagination-link-style:hover{
  background: none;
  color: #705AF3;
}

.btn-styling{
  margin: 0 auto;
  display: block;
}